import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logo from '../assets/trixlog_colorized.png'
import { HttpService, UserService } from '../services'
import { NotificationManager } from 'react-notifications'
import './style.css'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [authCustomers, setAuthCustomers] = useState([])
  const [customerId, setCustomerId] = useState(null);

  const history = useHistory()

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)

    HttpService.login(username, password).then(({ data }) => {
      if (data.authUser.multiCustomer) {
        setAuthCustomers(data.authUser.authCustomers)
      } else {
        NotificationManager.success('Bem vindo!');
        UserService.saveToken(data.token)

        history.push('/upload')
      }

      UserService.saveUsername(username)
    }).finally(() => {
      setLoading(false)
    })
  }

  const loginSecondStep = customerId => {
    setLoading(true);
    setCustomerId(customerId);

    HttpService.loginSecondStep(username, password, customerId).then(({ data }) => {
      NotificationManager.success('Bem vindo!')
      UserService.saveToken(data.token)

      history.push('/upload')
    }).catch(() => {
      NotificationManager.error('Erro desconhecido!')
    }).finally(() => {
      setLoading(false);
      setCustomerId(null);
    });
  }

  useEffect(() => {
    UserService.logout()
  }, [])

  return (
    <React.Fragment>
      <main role="main" className="container mt-5 col-md-4 ">
        <div className="card border-success text-center">
          <div className="card-header">
            <Link to="/login"><img src={logo} id="menu-toggle" style={{ width: '120px' }} alt=""></img></Link>
          </div>

          <div className="card-body">
            {authCustomers.length === 0 && (
              <form className="needs-validation" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationTooltip01">Usuário</label>
                    <input type="text" className="form-control" id="validationTooltip01" required value={username} onChange={event => setUsername(event.target.value)} />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationTooltip02">Senha</label>
                    <input type="password" className="form-control" id="validationTooltip01" required value={password} onChange={event => setPassword(event.target.value)} />
                  </div>
                </div>

                <button className={"btn " + (!username || !password || loading ? 'btn-secondary' : 'btn-success') + " col-md-12 mb-3"} disabled={(!username || !password)} type="submit" value="Submit">
                  {loading
                    ? <div>
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                      Carregando...
                    </div>
                    : <div>Entrar</div>
                  }
                </button>
              </form>
            )}

            {authCustomers.length > 0
              && <div className='customer-selection'>
                {authCustomers.sort((customerA, customerB) => String(customerA.nickname).localeCompare(customerB.nickname)).map(customer => (
                  <div className='d-flex justify-content-between' key={customer.id} onClick={() => loginSecondStep(customer.id)}>
                    <span>{customer.nickname || customer.name}</span>
                    {loading && customerId === customer.id && (
                      <div class="spinner-border spinner-border-sm text-success" role="status">
                        <span class="sr-only">Carregando...</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Login