import axios from 'axios';
import Paths, { getServerBaseUrl } from '../constants/paths'
import { UserService } from '../services'

export const commandsClient = axios.create({
    baseURL: getServerBaseUrl(),
    headers: {
        'Authorization': UserService.getToken()
    }
});

