export const UPDATE_REASONS = [
    {value: 'AUTOMATIC', label: 'Automático'},
    {value: 'MANUAL', label: 'Manual'}
]

export function getReasonLabel(key) {
    const entry = UPDATE_REASONS.filter(reason => reason.value === key);

    return entry[0].label;
}
