const Paths = {
  SERVER_QA: "https://qa-service-commands.trixlog.com",
  SERVER_PROD: "https://service-commands.trixlog.com"
};

const getServerUrl = (url) => {
  if (process.env.ENV === "production") {
    return `${Paths.SERVER_PROD}/${url}`;
  }

  return `${Paths.SERVER_QA}/${url}`;
};

const getServerBaseUrl = () => {
  if (process.env.ENV === "production") {
    return `${Paths.SERVER_PROD}`;
  }

  return `${Paths.SERVER_QA}`;
}

export default Paths;
export { getServerUrl, getServerBaseUrl };