import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { NotificationManager } from 'react-notifications'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { FILE_TYPES, getTypeLabel } from '../constants/fileTypes';
import { getReasonLabel } from '../constants/updateReason';
import { getStatusLabel, getStatusColor } from '../constants/updateStatus'
import { UserService } from '../services';
import { commandsClient } from '../services/commandsClient';
import './style.css'


export function FileLinking() {
    const PAGE_SIZE = 10;
    const blockCancelTypes = ['DOWNLOADED', 'SUCCESS', 'ERROR', 'CANCELED'];

    const history = useHistory();

    const [formState, setFormState] = useState({
        type: '',
        version: '',
        serials: '',
    });

    const [updates, setUpdates] = useState([]);

    const [totalUpdates, setTotalUpdates] = useState(0);

    const [page, setPage] = useState(0);

    const [updateHistory, setUpdateHistory] = useState([]);

    const [cancelLog, setCancelLog] = useState({});

    const [defaultVersions, setDefaultVersions] = useState([]);

    const [isLoadingUpdates, setIsLoadingUpdates] = useState(false);

    const [reloadUpdates, setReloadUpdates] = useState(false);
    
    const [autoRefresh, setAutoRefresh] = useState(false);


    useEffect(() => {
        document.title = "Associação de arquivos";

        if (!UserService.hasLoggedUser()) {
            history.push('/login');
        }

        setIsLoadingUpdates(true);
        setPage(0);

        commandsClient.get('/device/status', {
            params: {
                serials: formState.serials,
                type: formState.type,
                version: formState.version,
                page: 0,
                size: PAGE_SIZE,
            },
            headers: {
                'Authorization': UserService.getToken()
            }
        }).then(response => {
            setUpdates(response.data.results);
            setTotalUpdates(response.data.count);
            setIsLoadingUpdates(false);
        }).catch(() => {
            NotificationManager.error("Erro ao carregar histórico de atualizações ");
            setIsLoadingUpdates(false);
        });
    }, [history, formState, reloadUpdates]);
    
    useEffect(() => {
        commandsClient.get("/manager/search", {
            params: {
                type: formState.type,
                version: '',
            },
            headers: {
                'Authorization': UserService.getToken()
            }
        }).then(({ data }) => {
            setDefaultVersions(data.map(file => ({ value: file.version, label: formatDescription(file)})))
        }).catch(() => NotificationManager.error("Erro ao carregar as versões."));
        
        setFormState(prev => ({...prev, version: ''}));
    }, [formState.type]);

    useEffect(() => {
        setTimeout(() => {
            if (autoRefresh) {
                setReloadUpdates(!reloadUpdates);
            }
        }, 5000)
    }, [autoRefresh, reloadUpdates])
    
    return (
        <>
            <div className='mb-3'>
                <h3>Associação de arquivos</h3>
            </div>
            <div className='row'>
                <div className='col-8'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-row mb-3'>
                            <div className="col-6">
                                <select className="custom-select" value={formState.type} onChange={handleChange('type')}>
                                    <option value="">Tipo de arquivo</option>
                                    {FILE_TYPES.map(type => (
                                        <option key={type.value} value={type.value}>{type.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-6'>
                                <AsyncSelect
                                    isDisabled={formState.type === ""}
                                    isClearable
                                    onChange={handleChangeVersion}
                                    loadOptions={loadOptions}
                                    backspaceRemovesValue
                                    loadingMessage={() => "Carregando..."}
                                    noOptionsMessage={() => "Versão não encontrada"}
                                    placeholder="Versão"
                                    value={formState.version && {value: formState.version, label: formState.version}}
                                    defaultOptions={defaultVersions}
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Seriais (separados por vírgula)"
                                    id="version"
                                    value={formState.serials}
                                    onChange={handleChange('serials')}
                                />
                            </div>
                            <div className='col-2'>
                                <button
                                    disabled={
                                        formState.type === ''
                                        || formState.version === ''
                                        || formState.serials.split(',') < 1
                                    }
                                    className='btn btn-success float-right'
                                >
                                    Associar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='col-4 bg-light border pt-2'>
                    <div className='d-flex justify-content-between align-items-baseline mt-1'>
                        <Tooltip style={{maxWidth: 400}} anchorId="auto-refresh-check-container" content="Atualizar lista automaticamente a cada 5 segundos" />
                        <div className="form-group form-check" id="auto-refresh-check-container">
                            <input type="checkbox" className="form-check-input" id="auto-refresh-check" onClick={({target}) => setAutoRefresh(target.checked)} />
                            <label className="form-check-label" htmlFor="auto-refresh-check">Auto</label>
                        </div>
                        <h5 className='text-center'>Ultimas atualizações</h5>
                        <button className='btn btn-sm' onClick={() => setReloadUpdates(!reloadUpdates)}><i className='fa fa-refresh'></i></button>
                    </div>
                    <div
                        style={{
                            height: 'calc(100vh - 190px)',
                            overflowY: 'auto',
                            marginRight: -10,
                            paddingRight: 6
                        }}
                        className='thin-scrollbar'
                        id='status-log'
                    >
                        <InfiniteScroll
                            scrollableTarget='status-log'
                            dataLength={updates.length}
                            hasMore={updates.length < totalUpdates}
                            next={loadStatus}
                            loader={
                                <div className="row justify-content-center m-3 mt-4">
                                    <div className="spinner-grow text-success"></div>
                                </div>
                            }
                            endMessage={updates.length !== 0 &&
                                <div className="row align-items-center justify-content-center mb-2">
                                    <h6 className="text-secondary">Sem mais arquivos por aqui</h6>
                                </div>
                            }
                            style={{
                                overflowX: 'hidden'
                            }}
                        >
                            {isLoadingUpdates ? (
                                <div className='d-flex justify-content-center align-items-center' style={{height: '100%'}}>
                                    <div className="spinner-grow text-success" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                updates.length === 0 ? (
                                    <div className='d-flex justify-content-center align-items-center text-center' style={{height: '100%'}}>
                                        <h3 className='text-muted'>Nenhuma associação encontrada com os filtros selecionados</h3>
                                    </div>
                                ) : (
                                    updates.map((update, index) => (
                                        <div key={update.id} className='bg-white rounded-lg border px-2 pt-2 mb-2'>
                                            <div className='justify-content-between align-items-center d-flex'>
                                                <span className='badge badge-pill badge-info'>
                                                    {getReasonLabel(update.reason)}
                                                </span>
                                                <span className='badge badge-pill badge-secondary'>
                                                    {moment(update.createdAt).format('DD/MM/YYYY HH:mm')}
                                                </span>
                                                <span
                                                    className={`badge badge-pill badge-${getStatusColor(update.latestStatus.status)} text-wrap`}
                                                    style={{
                                                        maxWidth: '40%'
                                                    }}
                                                >
                                                    {getStatusLabel(update.latestStatus.status).toUpperCase()}
                                                </span>
                                            </div>
                                            <div className='p-1 mt-1'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <p>Tipo: {getTypeLabel(update.type)}</p>
                                                    </div>
                                                    <div className='col text-right'>
                                                        <p>Versão: {update.version}</p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <p className='text-truncate'>Dispositivo: {update.serial}</p>
                                                    </div>
                                                </div>
                                                <div className='row mb-3'>
                                                    <div className='col'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#updateHistoryModal"
                                                            onClick={handleUpdateHistoryOpen(index)}
                                                        >
                                                            Histórico de status
                                                        </button>
                                                    </div>
                                                    {canCancel(index) && 
                                                        <div className='col text-right'>
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                data-toggle="modal"
                                                                data-target="#cancelModal"
                                                                onClick={handleCancelOpen(index)}
                                                            >
                                                                 Cancelar
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="updateHistoryModal" tabIndex="-1" aria-labelledby="updateHistoryModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateHistoryModalLabel">Histórico de atualizações</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {updateHistory.map(history => (
                                <div className='row align-items-center my-1' key={history.date}>
                                    <div className='col'>
                                        <span className={`badge badge-${getStatusColor(history.status)} text-wrap`}>
                                            {getStatusLabel(history.status).toUpperCase()}
                                        </span>
                                    </div>
                                    <div className='col text-right'>
                                        <span className='badge badge-secondary'>
                                            {moment(history.date).format('DD/MM/YYYY HH:mm')}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateHistoryModalLabel">Deseja cancelar a atualização?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Você confirma o cancelamento da atualização do equipamento {cancelLog.serial}?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={() => handleCancel()} className="btn btn-danger" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    function loadStatus() {
        commandsClient.get('/device/status', {
            params: {
                serials: formState.serials,
                type: formState.type,
                version: formState.version,
                page: page + 1,
                size: PAGE_SIZE,
            },
            headers: {
                "Authorization": UserService.getToken()
            }
        }).then(response => {
            setUpdates(prev => [...prev, ...response.data.results]);
            setPage(page + 1);
        }).catch(() => NotificationManager.error("Erro ao carregar mais atualizações."));
    }

    function handleUpdateHistoryOpen(updateIndex) {
        return () => {
            setUpdateHistory(updates[updateIndex].statusHistory);
        }
    }

    function handleCancelOpen(cancelIndex){
        return () => {
            setCancelLog(updates[cancelIndex]);
        }

    }

    function handleChange(field) {
        return event => setFormState({...formState, [field]: event.target?.value});
    }

    function handleChangeVersion(version) {
        setFormState({...formState, version: version ? version.value : ''});
    }

    function formatDescription(file) {
        return file.version + ' - ' + file.name;
    }

    function canCancel(updateIndex) {
        return !blockCancelTypes.includes(updates[updateIndex].latestStatus.status)
    }

    async function loadOptions(searchValue, callback) {
        try {
            const { data } = await commandsClient.get("/manager/search", {
                params: {
                    type: formState.type,
                    version: searchValue,
                },
                headers: {
                    "Authorization": UserService.getToken()
                }
            });

            const options = data.map(option => ({ value: option.version, label: option.version}));

            callback(options);
        } catch (error) {
            NotificationManager.error("Não foi possível carregar as opções.");
        }
    }

    async function handleCancel(){
        try{
            await commandsClient.get('/device/cancel', {
                params: {
                    serial: cancelLog.serial,
                    type: cancelLog.type,
                    version: cancelLog.version
                },
                headers: {
                    "Authorization": UserService.getToken()
                }
            });
            setReloadUpdates(!reloadUpdates)
        } catch (error) {
            NotificationManager.error("Erro ao cancelar o processo de atualização.");
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        
        try {
            const { data } = await commandsClient.post('/device/update/file', {
                serials: formState.serials.split(','),
                version: formState.version,
                type: formState.type,
            },{
                headers: {
                    "Authorization": UserService.getToken()
                }
            });

            let unsuccessfulCommandsCount = 0;

            Object
                .keys(data.unsuccessfulSerialsByReasonCount)
                .forEach(reason => unsuccessfulCommandsCount += typeof data.unsuccessfulSerialsByReasonCount[reason] === 'number' ? data.unsuccessfulSerialsByReasonCount[reason] : 0);

            if (data.successfulSerialsCount > 0 && unsuccessfulCommandsCount === 0) {
                NotificationManager.success('Comandos criados com sucesso!');
            }

            if (data.successfulSerialsCount > 0 && unsuccessfulCommandsCount > 0) {
                NotificationManager.success(`Comandos criados com sucesso: ${data.successfulSerialsCount}`);
                NotificationManager.error(
                    <div>
                        <p>Comandos mal sucedidos: {unsuccessfulCommandsCount}</p>
                        <p>Motivos:</p>
                        {Object.keys(data.unsuccessfulSerialsByReason).map(reason => (
                            <div>
                                <p>{reason}:</p>
                                <ul>
                                    {data.unsuccessfulSerialsByReason[reason].map(serial => <li key={serial}>{serial}</li>)}
                                </ul>
                            </div>
                        ))}
                    </div>
                );
            }

            if (data.successfulSerialsCount === 0 && unsuccessfulCommandsCount > 0) {
                NotificationManager.error(
                    <div>
                        <p>Não foi possível criar os comandos. Motivo(s):</p>
                        {Object.keys(data.unsuccessfulSerialsByReason).map(reason => (
                            <p key={reason}>{reason}</p>
                        ))}
                    </div>
                );
            }

        } catch (error) {
            NotificationManager.error("Erro ao associar arquivo ao dispositivo.");
        }

        setReloadUpdates(!reloadUpdates);
    }
}