import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { commandsClient } from "../services/commandsClient"
import { NotificationManager } from 'react-notifications';
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./style.css"
import { UserService } from "../services";
import { FILE_TYPES, getTypeLabel, TYPE_CODE } from '../constants/fileTypes'

export function FileUpload() {
    const PAGE_SIZE = 20;

    const history = useHistory();

    const [typeFilterValue, setTypeFilterValue] = useState("");
    const [sending, setSending] = useState(false);

    useEffect(() => {
        document.title = "Gerenciamento de arquivos";

        if (!UserService.hasLoggedUser()) {
            history.push('/login');
        }
    }, [history]);

    useEffect(() => {
        setLoadingFiles(true);
        setPage(0);

        commandsClient.get("/manager", {
            params: {
                type: typeFilterValue,
                page: 0,
                size: PAGE_SIZE
            },
            headers: {
                'Authorization': UserService.getToken()
            },
        }).then(response => {
            setFiles(response.data.results);
            setTotalFiles(response.data.count);
            setLoadingFiles(false);
        }).catch(reason => {
            NotificationManager.error("Erro ao carregar a lista de arquivos.");
            setLoadingFiles(false);
        });
    }, [typeFilterValue, sending]);

    const [fileData, setFileData] = useState({
        version: '',
        type: '',
        file: null,
        description: ''
    });
    
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [totalFiles, setTotalFiles] = useState(0);
    const [page, setPage] = useState(0);
    const [deletingFile, setDeletingFile] = useState("");

    return (
        <>
            <div className="mb-3">
                <h3>Gerenciamento de arquivos</h3>
            </div>
            <div className="border bg-light mb-3 container py-3">
                <div className="row">
                    <div className="col">
                        <h5>Lista de arquivos</h5>
                    </div>
                </div>
                <div className="row mb-3">
                        <div className="col-5 input-group align-items-end">
                        <label htmlFor="file-type-filter" className="mx-3">Filtrar por tipo de arquivo: </label>
                        <select className="custom-select" id="file-type-filter" value={typeFilterValue} onChange={handleChangeTypeFilter}>
                            <option value="">Todos</option>
                            {FILE_TYPES.map(type => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {loadingFiles ? (
                    <div className="row justify-content-center m-3 mt-4">
                        <div className="spinner-grow text-success"></div>
                    </div>
                ) : (
                        <div className="px-3 pt-3 bg-white rounded-5 border file-list-container" id="file-list">
                            <InfiniteScroll
                                scrollableTarget="file-list"
                                next={loadFiles}
                                dataLength={files.length}
                                hasMore={files.length < totalFiles}
                                loader={
                                    <div className="row justify-content-center m-3 mt-4">
                                        <div className="spinner-grow text-success"></div>
                                    </div>
                                }
                                endMessage={files.length !== 0 &&
                                    <div className="row align-items-center justify-content-center mb-2">
                                        <h6 className="text-secondary">Sem mais arquivos por aqui</h6>
                                    </div>
                                }
                                style={{
                                    overflowX: 'hidden'
                                }}
                            >
                                    {files.length === 0 ? (
                                        <div className="row align-items-center justify-content-center mb-2">
                                            <h4 className="text-secondary">Sem arquivos por enquanto.</h4>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row align-items-center mb-3 pb-2 border-bottom">
                                                <div className="col-2 text-center">
                                                    Nome
                                                </div>
                                                <div className="col-4 text-center">
                                                    Descrição
                                                </div>
                                                <div className="col-2 text-center">
                                                    Tipo
                                                </div>
                                                <div className="col-1 text-center">
                                                    Versão
                                                </div>
                                                <div className="col-2 text-center">
                                                    Link de download
                                                </div>
                                                <div className="col-1 text-center">
                                                    Ação
                                                </div>
                                            </div>
                                            {files.map(file => (
                                                <>
                                                    <div className="row align-items-center mb-3" key={file.id}>
                                                        <div className="col-2 text-center">
                                                            {file.name}
                                                        </div>
                                                        <div className="col-4 text-center text-truncate" id={`description-tooltip-${file.id}`}>
                                                            {file.description}
                                                        </div>
                                                        <div className="col-2 text-center">
                                                            {getTypeLabel(file.type)}
                                                        </div>
                                                        <div className="col-1 text-center">
                                                            {file.version}
                                                        </div>
                                                        <div className="col-2 text-center">
                                                            <button className="btn btn-link" onClick={downloadFile(file)}>Baixar arquivo</button>
                                                        </div>
                                                        <div className="col-1 text-center">
                                                            <button className="btn btn-danger" data-toggle="modal" data-target="#confirmDeleteModal" onClick={handleDeleteFileClick(file.id)}><i className="fa fa-trash"></i></button>
                                                        </div>
                                                    </div>
                                                    <Tooltip style={{maxWidth: 400}} anchorId={`description-tooltip-${file.id}`} content={file.description} />
                                                </>
                                            ))}
                                        </>
                                    )}
                            </InfiniteScroll>
                        </div>
                    )
                }
            </div>
            <form onSubmit={handleSubmit}>
                <div className="form-row mb-3">
                    <div className="col-3">
                        <select className="custom-select" disabled={sending} value={fileData.type} onChange={handleChange('type')}>
                            <option value="" disabled>Tipo de arquivo</option>
                            {FILE_TYPES.map(type => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <input type="text" disabled={sending} className="form-control" placeholder="Versão" id="version" value={fileData.version} onChange={handleChange('version')} />
                    </div>
                    <div className="col-5">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" disabled={sending} id="file" onChange={handleChange('file')} />
                            <label className="custom-file-label" htmlFor="file">{fileData.file?.name || ''}</label>
                        </div>
                    </div>
                </div>
                <div className="form-row align-items-center">
                    <div className="col-11">
                        <input type="text" disabled={sending} className="form-control" placeholder="Descrição" id="description" value={fileData.description} onChange={handleChange('description')} />
                    </div>
                    <div className="col-1">
                        <button
                            className="btn btn-success btn-block float-right"
                            disabled={fileData.type === '' || fileData.version === '' || fileData.file === null || sending}
                        >
                            Enviar
                        </button>
                    </div>
                </div>
                <div className="form-row align-items-center mt-2 px-1">
                    <div className={`progress col-12 ${sending ? 'visible' : 'invisible'}`} style={{padding: 0}} >
                        <div className="progress-bar bg-success" style={{width: `${progress}%`}}></div>
                    </div>
                </div>
            </form>
            <div className="modal fade" id="confirmDeleteModal" tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="confirmDeleteModalLabel">Deletar arquivo</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    Tem certeza que deseja remover este aquivo?
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDeleteFileCancel}>Cancelar</button>
                    <button disabled={deletingFile === ""} type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleDelete}>Confirmar</button>
                </div>
                </div>
            </div>
            </div>

        </>
    )

    function downloadFile(file) {
        return () => {
            commandsClient.get('/device/download', {
                params: {
                    ESN: '',
                    File: TYPE_CODE[file.type],
                    Ver: file.version,
                    manualDownload: true
                },
                headers: {
                    'Authorization': UserService.getToken()
                },
                responseType: 'blob'
            }).then(response => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', file.name);
                
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

                URL.revokeObjectURL(href);
            })
        }
    }

    function loadFiles() {
        commandsClient.get("/manager", {
            params: {
                type: typeFilterValue,
                page: page + 1,
                size: PAGE_SIZE
            },
            headers: {
                'Authorization': UserService.getToken()
            },
        }).then(response => {
            setFiles(prev => [...prev, ...response.data.results]);
            setPage(page + 1);
        }).catch(() => {
            NotificationManager.error("Erro ao carregar mais arquivos.");
        });
    }

    function handleDeleteFileClick(id) {
        return () => {
            setDeletingFile(id);
        }
    }

    function handleDeleteFileCancel() {
        setDeletingFile("");
    }

    function handleChange(field) {
        return event => {
            if (field === 'file') {
                setFileData({...fileData, [field]: event.target.files[0]});
            } else {
                setFileData({...fileData, [field]: event.target.value});
            }
        }
    }

    function handleChangeTypeFilter(event) {
        setTypeFilterValue(event.target.value);
    }

    async function handleDelete() {
        try {
            const response = await commandsClient.delete(`/manager/${deletingFile}`, {
                headers: {
                    'Authorization': UserService.getToken()
                }
            });
            
            setFiles(prev => prev.filter(file => file.id !== response.data.id));
            setTotalFiles(totalFiles - 1);

            NotificationManager.success("Arquivo deletado com sucesso!");
        } catch (error) {
            NotificationManager.error("Erro ao deletar o arquivo.");
        }

        setDeletingFile("");
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setSending(true);

        const formData = new FormData();

        formData.append('file', fileData.file);
        formData.append('version', fileData.version);
        formData.append('type', fileData.type);
        formData.append('description', fileData.description);
        
        try {
            await commandsClient.post('/manager', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": UserService.getToken()
                },
                onUploadProgress: progressEvent => {
                    const progress = (100 * progressEvent.loaded) / progressEvent.total;
                    setProgress(progress);
                }
            });

            NotificationManager.success(`Sucesso ao enviar o arquivo ${fileData.file.name}!`)
            setSending(false);
            setFileData({
                version: '',
                type: '',
                description: '',
                file: null,
            });
        } catch (error) {
            NotificationManager.error(`Erro ao enviar o arquivo ${fileData.file.name}.`)
            setSending(false);
        }
    }
}