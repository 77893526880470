export const UPDATE_STATUS = [
    {value: 'STARTED', label: 'Iniciado', color: 'info'},
    {value: 'SENT', label: 'Enviado', color: 'info'},
    {value: 'DOWNLOADED', label: 'Baixado', color: 'info'},
    {value: 'WAITING_CONNECTION', label: 'Aguardando conexão', color: 'info'},
    {value: 'COMMAND_RECEIVED', label: 'Comando recebido', color: 'info'},
    {value: 'SUCCESS', label: 'Sucesso', color: 'success'},
    {value: 'ERROR', label: 'Erro', color: 'danger'},
    {value: 'CANCELED', label: 'Cancelado', color: 'danger'},
]

export function getStatusLabel(key) {
    const entry = UPDATE_STATUS.filter(status => status.value === key);

    return entry[0].label;
}

export function getStatusColor(key) {
    const entry = UPDATE_STATUS.filter(status => status.value === key);

    return entry[0].color;
}
