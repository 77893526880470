export const FILE_TYPES = [
    {value: "FIRMWARE", label: "Firmware"},
    {value: "CONFIGURATION", label: "Configuração"},
    {value: "DRIVER_ID", label: "Embarque de condutor"},
    {value: "GEO_ZONE", label: "Geo-zona"},
    {value: "APN", label: "APN"},
];

export const TYPE_CODE = {
    FIRMWARE: 0,
    CONFIGURATION: 1,
    DRIVER_ID: 9,
    GEO_ZONE: 11,
    APN: 15
}

export function getTypeLabel(key) {
    const entry = FILE_TYPES.filter(type => type.value === key);

    return entry[0].label;
}