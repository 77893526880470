import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Upload from './container/Upload';
import Update from './container/Update';
import Login from './container/Login';
import Default from './container/Default';
import Status from './container/Status';
import { FileUpload } from './container/FileUpload';
import { FileLinking } from './container/FileLinking';
import './config/axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path='/login' component={Login} />
            <Route exact>
                <App>
                    <Switch>
                        <Route path='/upload' component={Upload} />
                        <Route path='/update' component={Update} />
                        <Route path='/status' component={Status} />
                        <Route path='/fileupload' component={FileUpload} />
                        <Route path='/filelinking' component={FileLinking} />
                        <Route component={Default} />
                    </Switch>
                </App>
            </Route>
        </Switch>
        <NotificationContainer>

        </NotificationContainer>
    </BrowserRouter>
    ,
    document.getElementById('root')
);